import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    sidebarShow: true
  },
  reducers: {
    setSidebarShow(state, action) {
      state.sidebarShow = action.payload;
    }
  }
});

export const { setSidebarShow } = menuSlice.actions;

export const menuSelector = (state) => state.menu;
