import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import client from '../feathers';

export const loginUser = createAsyncThunk(
  'users/loginUser',
  async ({ username, password, from = null }, thunkAPI) => {
    try {
      const result = await client.authenticate({
        strategy: 'local',
        username,
        password
      });

      return result.user;
    } catch (e) {
      // console.log(e)
      return thunkAPI.rejectWithValue({
        code: e.code,
        message: e.message
      });
    }
  }
);

export const reauthenticateUser = createAsyncThunk(
  'users/reauthenticateUser',
  async (thunkAPI) => {
    try {
      const result = await client.reAuthenticate();
      return result.user;
    } catch (e) {
      // console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'users/logoutUser',
  async (thunkAPI) => {
    try {
      await client.logout();
      return true;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    reauthenticated: false,
    errorMessage: '',
    errorCode: null
  },
  reducers: {},
  extraReducers: {
    // Extra reducer comes here
    [loginUser.fulfilled]: (state, { payload }) => {
      // state.email = payload.email;
      // state._id = payload._id;
      state.user = payload;
      state.isFetching = false;
      state.isSuccess = true;
      // state.isError = false;
      // state.errorMessage = "";
      // state.errorCode = null;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log('payload', payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.errorCode = payload.code;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
      state.errorCode = null;
    },
    [reauthenticateUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isFetching = false;
      state.isSuccess = true;
      // state.isError = false;
      // state.errorMessage = "";
      // state.errorCode = null;
      state.reauthenticated = true;
      return state;
    },
    [reauthenticateUser.rejected]: (state, { payload }) => {
      // console.log('payload', payload);
      state.isFetching = false;
      state.isError = false;
      state.reauthenticated = true;
      //state.errorMessage = payload.message;
    },
    [reauthenticateUser.pending]: (state) => {
      state.isFetching = true;
    },
    [logoutUser.fulfilled]: (state, { payload }) => {
      state.user = null;
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
    [logoutUser.rejected]: (state, { payload }) => {
      console.log('payload', payload);
    },
    [logoutUser.pending]: (state) => {}
  }
});

export const userSelector = (state) => state.user;
