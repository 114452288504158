import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './features/userSlice';
import { menuSlice } from './features/menuSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menu: menuSlice.reducer
  }
});

/*
import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
*/
